import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from 'react-router-class-tools';

//components
import Category from "./Category";

import Error from "./Error";
import Loader from "./Loader";

import { withThemeContext } from "../redesign/components/StitchesThemeProvider";
import { TaboolaNewsroomWrapper } from "../helpers/TaboolaNewsroom";

@inject("CategoryStore", "UIStore")
@observer
class CategoryPage extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.slug !== this.props.match.params.slug) {
      this.props.CategoryStore.updateSlug(nextProps.match.params.slug);
    }
  }

  componentWillMount(nextProps) {
    this.props.CategoryStore.updateSlug(this.props.match.params.slug);
  }

  render() {
    const { CategoryStore, UIStore } = this.props;
    // console.log(CategoryStore.fetchedCategory.category.OID);
    // console.log('[ES] CategoryPage ', CategoryStore.status, this.props.match);

    if (CategoryStore.status === "loading") return <Loader />;
    if (CategoryStore.status === "error")
      return <Error error={CategoryStore.message} />;

    // category is available
    const { changeUIType, trackPageView } = UIStore;
    const category = CategoryStore.fetchedCategory.category;
    category.OID === 0
      ? this.props.UIStore.setCurrentAction([
        "category",
        "category-" + category.type,
      ])
      : this.props.UIStore.setCurrentAction(["category-sg", "category"]);
    document.title = category.seo_title;
    trackPageView({ title: category.seo_title });
    changeUIType(category);

    if (category.isDarkMode) {
      this.props.setForceDarkMode(true);
    }

    const isHomePage = !this.props.match.params.slug;

    return (
      <TaboolaNewsroomWrapper newsroomPage={isHomePage ? 'home' : undefined}>
        <Category store={CategoryStore} category={category} />
      </TaboolaNewsroomWrapper>
    );

  }
}

export default withRouter(withThemeContext(CategoryPage));
